var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: _vm.ae$lng("Voyage Information"),
                              "no-maximize": "",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Date of departure"),
                                        name: "dateOfDeparture",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["idCargoVoyage"][
                                            "departureDate"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["idCargoVoyage"],
                                            "departureDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['idCargoVoyage']['departureDate']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Voyage number"),
                                        name: "voyageNumber",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["idCargoVoyage"][
                                            "voyageNumber"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["idCargoVoyage"],
                                            "voyageNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['idCargoVoyage']['voyageNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "2" } },
                                  [
                                    !_vm.viewMode
                                      ? _c("asyent-form-finder", {
                                          ref: "lastPortOfCallCode",
                                          attrs: {
                                            "working-date": _vm.workingDate,
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "service-name": "catalog",
                                            catalog: "CatalogPlaceOfLoading",
                                            "data-fields": _vm.dataFields,
                                            props: {},
                                            label: _vm.ae$lng(
                                              "Last Port of Call Code"
                                            ),
                                            "item-value": "code",
                                            name: "lastPortOfCallCode",
                                            "as-text": "",
                                            "is-not-cacheable": true,
                                            "pre-poplated": false,
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["idCargoVoyage"][
                                                "departurePort"
                                              ]["code"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF["idCargoVoyage"][
                                                  "departurePort"
                                                ],
                                                "code",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['idCargoVoyage']['departurePort']['code']",
                                          },
                                        })
                                      : _c("asyent-form-display", {
                                          attrs: {
                                            value: [
                                              _vm.formDataF["idCargoVoyage"][
                                                "departurePort"
                                              ]["code"],
                                              _vm.formDataF["idCargoVoyage"][
                                                "departurePort"
                                              ]["name"],
                                            ],
                                            label: _vm.ae$lng(
                                              "Last Port of Call Code"
                                            ),
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("ItemsAttributes", {
                          attrs: {
                            "is-disabled-func": _vm.isDisabled,
                            "item-headings": [
                              {
                                text: _vm.ae$lng("number"),
                                value: "number",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("familyName"),
                                value: "familyName",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("givenName"),
                                value: "givenName",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("nationality"),
                                value: "nationality",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("countryOfBirth"),
                                value: "countryOfBirth",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("placeOfBirth"),
                                value: "placeOfBirth",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("dateOfBirth"),
                                value: "dateOfBirth",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("idType"),
                                value: "idType",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("idDocumentNumber"),
                                value: "idDocumentNumber",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("portOfEmbarkation"),
                                value: "portOfEmbarkation.code",
                                sortable: false,
                              },

                              {
                                text: _vm.ae$lng("portOfDisembarkation"),
                                value: "portOfDisembarkation.code",
                                sortable: false,
                              },

                              {
                                text: _vm.ae$lng("isTransit"),
                                value: "isTransit",
                                sortable: false,
                              },
                              {
                                text: _vm.ae$lng("visaStatus"),
                                value: "visaStatus",
                                sortable: false,
                              },
                            ],
                            "item-values": [
                              "number",
                              "familyName",
                              "givenName",
                              "nationality",
                              "countryOfBirth",
                              "placeOfBirth",
                              "dateOfBirth",
                              "idType",
                              "idDocumentNumber",
                              "portOfEmbarkation",
                              "portOfDisembarkation",
                              "isTransit",
                              "visaStatus",
                            ],
                            readonly: _vm.isReadonly,
                            name: "passengersItem",
                            title: _vm.ae$lng("passengersItem"),
                          },
                          model: {
                            value: _vm.formDataF["passengersItems"],
                            callback: function ($$v) {
                              _vm.$set(_vm.formDataF, "passengersItems", $$v)
                            },
                            expression: "formDataF['passengersItems']",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            transition: "dialog-bottom-transition",
            width: "800px",
          },
          model: {
            value: _vm.uploadDialog,
            callback: function ($$v) {
              _vm.uploadDialog = $$v
            },
            expression: "uploadDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", "max-height": "56" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.ae$lng("passengers"))),
                  ]),
                  _c("v-spacer"),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": "Close",
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: {
                      confirmed: function ($event) {
                        _vm.uploadDialog = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "0.85rem" } },
                                [_vm._v("Upload File")]
                              ),
                              _c("v-file-input", {
                                key: _vm.randKey,
                                attrs: {
                                  label: "",
                                  "prepend-icon": "",
                                  "prepend-inner-icon": "mdi-paperclip",
                                  "show-size": "",
                                  "truncate-length": "15",
                                  dense: _vm.appConfig.forms.dense,
                                  flat: _vm.appConfig.forms.flat,
                                  solo: _vm.appConfig.forms.solo,
                                  "solo-inverted":
                                    _vm.appConfig.forms.soloInverted,
                                  filled: _vm.appConfig.forms.filled,
                                  outlined: _vm.appConfig.forms.outlined,
                                },
                                on: { change: _vm.uploadTemplate },
                                model: {
                                  value: _vm.file,
                                  callback: function ($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            transition: "dialog-bottom-transition",
            width: "800px",
            height: "400px",
          },
          model: {
            value: _vm.errorsDialog,
            callback: function ($$v) {
              _vm.errorsDialog = $$v
            },
            expression: "errorsDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", "max-height": "56" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.ae$lng("Upload Errors"))),
                  ]),
                  _c("v-spacer"),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": "Close",
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: {
                      confirmed: function ($event) {
                        _vm.errorsDialog = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("h6", {
                                staticClass:
                                  "text-subtitle-1 font-weight-bold text-capitalize mb-1",
                              }),
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            _vm._l(_vm.uploadErrors, function (error, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "class",
                                      rawName: "v-class",
                                      value: _vm.text - error,
                                      expression: "text - error",
                                    },
                                  ],
                                  key: index,
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(index + 1) +
                                      "). " +
                                      _vm._s(error.message) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }